<script setup>
import { defineComponent, reactive, toRefs, ref } from 'vue'
import { Search } from '@element-plus/icons'
import { useRouter } from 'vue-router'

const emit = defineEmits(['search', 'back', 'handleCommand'])

let keyword = ref('')

let goBack = () => {
  emit('back')
}

let searchChange = (e) => {
  emit('search', e)
}

let handleCommand = (val) => {
  emit('handleCommand', val)
}
</script>

<template>
  <div class="back" @click="goBack">
    <i class="icon-xiadan-fanhui iconfont"></i> Go Back
  </div>
  <div class="title">Personalized product</div>
  <div class="flex">
    <div class="search_input">
      <el-input
        style="width: 100%; height: 100%"
        placeholder="Search"
        v-model.trim="keyword"
        :prefix-icon="Search"
        @change="searchChange"
      ></el-input>
    </div>
    <div style="margin-left: 10px">
      <el-dropdown @command="handleCommand">
        <el-button type="primary" style="padding-right: 5px">
          Select Template
          <el-icon class="el-icon--right"><arrow-down /></el-icon>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item :command="0">ALl Template</el-dropdown-item>
            <el-dropdown-item :command="2">System Template</el-dropdown-item>
            <el-dropdown-item :command="3">User Template</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<style scoped lang="scss">
.back {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  line-height: 22px;
  margin-top: 58px;
  cursor: pointer;
  display: inline-block;
}
.title {
  font-size: 36px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 50px;
  margin-top: 26px;
  margin-bottom: 30px;
}
.search_input {
  flex: 1;
  // margin-right: 80px;
}
.select_name {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  line-height: 41px;
  margin-right: 15px;
}
</style>
