import request from '@/utils/request.js'



export function getProductPaginate ({
  page,
  perPage,
  keyword,
  designType
}) {
  return request({
    url: '/product/getProductPaginate',
    method: 'get',
    params: {
      page: page || 1,
      perPage: perPage || 12,
      keyword: keyword || undefined,
      designType: designType.length > 0 ? designType : [2, 3],     // 2 => 获取系统模板产品
    }
  })
}


//获取系统模板列表
export function getSystemTemplates ({
  page,
  perPage,
  keyword,
  designType
}) {
  return request({
    url: '/design/getSystemTemplates',
    method: 'get',
    params: {
      page: page || 1,
      perPage: perPage || 12,
      keyword: keyword || undefined,
      designType: designType || '2,3'
    }
  })
}
