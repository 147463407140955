<script setup>
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import damaged from "@/assets/error/damaged.png";
import { encode } from "js-base64";
import Store from "store";
import { syncSystemTemplate } from "@/api/dashboard.js";
import { ElMessage } from "element-plus";
const prop = defineProps({
  template: {
    type: Object,
    default: () => ({}),
  },
});

let emit = defineEmits(["currentChange"]);

let setImg = computed(() => {
  return (item) => {
    if (!!item.img && Array.isArray(item.img) && item.img.length > 0)
      return item.img[0].img;
  };
});

let setTemplateType = computed(() => {
  return (e) => {
    if (e.type === 2) {
      return "System Template";
    }
    return "User Template";
  };
});

let router = useRouter();

let editListing = (e) => {
  router.push(`/store/productEdit/${e.id}`);
};

//回到顶部
let toTop = () => {
  let top = document.documentElement.scrollTop || document.body.scrollTop;
  // 实现滚动效果
  const timeTop = setInterval(() => {
    document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
    if (top <= 0) {
      clearInterval(timeTop);
    }
  }, 10);
};

//分页变动
let currentChange = (e) => {
  emit("currentChange", e);
};

let checkList = ref([]);

//重新设计
let editDesign = (e) => {
  //回调地址
  let path = encode(encodeURIComponent(window.location.href));

  //token
  let token;
  if (process.env.VUE_APP_CUSTOM_ENV === "development") {
    token = process.env.VUE_APP_TOKEN;
  } else {
    token = Store.get("diyToken");
  }

  // 地址
  let url = process.env.VUE_APP_TEMPLATE_DESIGN;
  //id 设计id
  //user_code   用户id
  //product_id  产品id
  //store_code   店铺code
  const {
    id,
    store_code,
    product: { id: productId },
    user_code,
  } = e;

  let designPath = `${url}?productId=${productId}&designId=${id}&backUrl=${path}&token=${token}&storeCode=${store_code}&merchantId=${user_code}`;
  console.log(designPath);

  window.location.href = designPath;
};

//同步设计   designType = 2
let syncDesign = async (item) => {

  if (Number(item.type === 2)) {
    item.asyncDesignLoading = true
    try {
      const { message } = await syncSystemTemplate({
        designId: item.id,
      });
      ElMessage.success(message);
    } catch (error) {
      ElMessage.error(error?.message || error);
    }
  } else {
    console.error("require type = 2");
  }
  item.asyncDesignLoading = false
};
</script>

<template>
  <div class="list_box">
    <el-row class="el_row" v-for="item in template.list" :key="item.id">
      <el-col :span="1">
        <el-checkbox-group v-model="checkList">
          <el-checkbox :label="item.id" />
        </el-checkbox-group>
      </el-col>
      <el-col :span="15" class="flex">
        <div class="img">
          <el-image :src="setImg(item)">
            <template #error>
              <el-image :src="damaged"></el-image>
            </template>
          </el-image>
        </div>
        <div style="flex: 1">
          <div style="margin-bottom: 10px">
            <div class="product_name mb_6">
              <span>{{ item.name }}</span>
              <span style="margin-left: 5px">
                ( {{ setTemplateType(item) }} )</span
              >
            </div>
            <!-- <span>Personalized Gifts / Phone Watch</span> -->
          </div>
          <!-- <div class=" size_color mb_6">1 colors · 5 sizes</div> -->
          <div class="flex" style="margin-right: 10px">
            <div class="size_color">{{ item.updated_at }}</div>
            <div class="tags">
              <div
                class="tags_box"
                v-for="(item, index) in item.tags"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="8" style="line-height: 70px" class="btn_box">
        <el-button
          type="info"
          @click="syncDesign(item)"
          v-if="Number(item.type) === 2"
          :loading="item.asyncDesignLoading"
          >Sync</el-button
        >
        <el-button type="info" @click="editDesign(item)">Edit Design</el-button>
        <el-button type="info" @click="editListing(item)"
          >Edit listing</el-button
        >
      </el-col>
    </el-row>

    <div v-if="template?.list?.length <= 0" class="noData">No Data</div>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="template.meta.total"
      :page-size="Number(template.meta.per_page)"
      @current-change="currentChange"
      hide-on-single-page
    />

    <div class="backTop" @click="toTop" v-if="template?.list?.length > 5">
      Back up to top
      <el-icon style="line-height: 35px; margin-left: 10px" :size="20"
        ><top
      /></el-icon>
    </div>
  </div>
</template>

<style scoped lang="scss">
.btn_box {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: flex-end;
}
.noData {
  margin-top: 50px;
  text-align: center;
  font-size: 25px;
  font-weight: 600;
}
:deep(.el-checkbox__label) {
  display: none;
}
:deep(.el-pagination) {
  margin-top: 30px;
  text-align: center;
  margin-bottom: 60px;
  // padding-bottom: 90px;
}
.mb_6 {
  margin-bottom: 6px;
}
.list_box {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 30px;
  min-height: 200px;
  .el_row {
    padding: 20px 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    .img {
      width: 67px;
      height: 67px;
      margin-right: 32px;
    }
    .product_name {
      font-size: 14px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      line-height: 20px;
      margin-right: 10px;
    }
    .size_color {
      width: 150px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 20px;
    }

    .tags {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      margin-right: 10px;
      &_box {
        line-height: 35px;
        margin-left: 15px;
        color: rgba(0, 0, 0, 0.85);
        background: #e5e5e5;
        padding: 0 10px;
        border-radius: 35px;
        font-size: 12px;
        min-width: 45px;
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }
}

.backTop {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-right: 35px;
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
  padding-bottom: 20px;
  padding-top: 20px;
}
</style>
