<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { encode } from 'js-base64'
import Store from 'store'
let props = defineProps({
  productPaginateList: {
    type: Array,
    default: () => [],
  },
  meta: {
    type: Object,
    default: () => ({}),
  },
})

let emit = defineEmits(['currentChange'])

let active = ref(false)
let mouseenterProducts = (item) => {
  //移入
  item.show = true
}

let mouseleaveProducts = (item) => {
  //移出
  item.show = false
}

let currentChange = (e) => {
  emit('currentChange', e)
}

//回到顶部
let toTop = () => {
  let top = document.documentElement.scrollTop || document.body.scrollTop
  // 实现滚动效果
  const timeTop = setInterval(() => {
    document.body.scrollTop = document.documentElement.scrollTop = top -= 50
    if (top <= 0) {
      clearInterval(timeTop)
    }
  }, 10)
}

const store = useStore()

//店铺code
let storeCode = computed(() => store.state.user?.userInfo?.defaultStoreCode)

//商户id
const userCode = computed(() => store.state.user?.userInfo?.userCode)

let personalizedDesign = (e) => {
  //1. `productId` 产品id
  // 2. `userCode` 商户id（用户id）
  // 3. `token` 登陆令牌
  // 4. `designId` 设计id（非重复设计时不传）
  // 5. backUrl 回调地址
  // storeCode 店铺 code
  //1. 测试：`personalization.hapydesign.com`

  let token
  if (process.env.VUE_APP_CUSTOM_ENV === 'development') {
    token = process.env.VUE_APP_TOKEN
  } else {
    token = Store.get('diyToken')
  }

  // 产品id
  let productId = e.id

  //回调地址
  let path = encode(encodeURIComponent(window.location.href))

  let url = process.env.VUE_APP_TEMPLATE_DESIGN
  let urlPath = `${url}?token=${token}&productId=${productId}&merchantId=${userCode.value}&storeCode=${storeCode.value}&backUrl=${path}`
  console.log(urlPath)
  window.location.href = urlPath
}
</script>

<template>
  <div class="product">
    <div class="flex flex_wrap">
      <div
        class="product_box"
        v-for="item in productPaginateList"
        :key="item"
        @mouseenter="mouseenterProducts(item)"
        @mouseleave="mouseleaveProducts(item)"
      >
        <div class="product_box_img">
          <el-image
            style="width: 100%; height: 100%"
            :src="item.img.img"
            :alt="item.name"
          ></el-image>
        </div>
        <div style="padding: 16px">
          <div class="product_box_name">
            {{ item.name }}
          </div>
          <div class="product_box_money">${{ item.price }}</div>
          <div class="product_box_factory">
            {{ item.factory_info.enname || 'No FactoryCode' }}
          </div>
          <div class="product_box_template">
            {{
              Number(item.design_type) === 2
                ? 'System Template'
                : 'User Template'
            }}
          </div>
        </div>

        <div v-if="item.show" class="shadow">
          <div class="btn">
            <el-button type="primary" @click="personalizedDesign(item)"
              >Personalized design</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="meta.total"
        :page-size="Number(meta.per_page)"
        @current-change="currentChange"
        :hide-on-single-page="true"
      />
    </div>
    <div class="backTop" @click="toTop" v-if="productPaginateList.length > 8">
      Back up to top
      <el-icon style="line-height: 35px; margin-left: 10px" :size="20"
        ><top
      /></el-icon>
    </div>
  </div>
</template>

<style scoped lang="scss">
.shadow {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9;
}
.btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.backTop {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-right: 50px;
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 60px;
}
::deep(.el-pagination) {
  text-align: center;
}
.flex {
  display: flex;
}
.flex_wrap {
  flex-wrap: wrap;
}
.product {
  margin-top: 30px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 25px 0;
  &_box {
    position: relative;
    width: 245px;
    border-radius: 3px 3px 0px 0px;
    // background-color: aqua;
    margin-left: 28px;
    margin-bottom: 29px;
    &_img {
      width: 245px;
      height: 245px;
      border-radius: 3px 3px 0px 0px;
      border: 1px solid rgba(0, 0, 0, 0.03);
    }
    &_name {
      font-size: 16px;
      font-weight: 400;
      height: 44px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 22px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    &_money {
      font-size: 16px;
      font-weight: 600;
      color: #ff2379;
      line-height: 22px;
      margin-top: 16px;
    }
    &_factory {
      margin-top: 10px;
    }
    &_template {
      margin-top: 10px;
    }
  }
}
</style>
