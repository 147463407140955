<script setup>
import { ref } from 'vue'
import { Search } from '@element-plus/icons'

let keyword = ref('')

const emit = defineEmits(['keywordChange', 'newProduct', 'changeTemplate'])

//input 回车失焦
let keywordChange = (e) => {
  emit('keywordChange', e)
}
//创建产品
let newProduct = () => {
  emit('newProduct')
}

let handleCommand = (val) => {
  emit('changeTemplate', val)
}
</script>

<template>
  <div class="top">
    <div class="title">Personalized product settings</div>
    <el-button type="primary" class="btn" @click="newProduct"
      >+ New product</el-button
    >
  </div>
  <div class="flex">
    <el-input
      placeholder="Search"
      :prefix-icon="Search"
      v-model="keyword"
      @change="keywordChange"
      clearable
    ></el-input>
    <div style="margin-left: 10px">
      <el-dropdown @command="handleCommand">
        <el-button type="primary" style="padding-right: 5px">
          Select Template
          <el-icon class="el-icon--right"><arrow-down /></el-icon>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item :command="0">All Template</el-dropdown-item>
            <el-dropdown-item :command="2">System Template</el-dropdown-item>
            <el-dropdown-item :command="3">User Template</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<style scoped lang="scss">
.top {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 30px;

  .title {
    font-size: 36px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    line-height: 50px;
  }

  .btn {
    width: 159px;
    height: 40px;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
  }
}
</style>
