import request from "@/utils/request.js";

/**
 * 获取完成的步骤
 */
export function getCurrStep() {
  return request({
    url: "/user/currentStep",
    method: "get",
    params: {},
  });
}

/**
 * 获取最新订单
 */
export function getOrderList() {
  return request({
    url: "/order/list",
    method: "get",
    params: {},
  });
}

/**
 * 获取店铺列表
 */
export function getStoreList() {
  return request({
    url: "/user/storeList",
    method: "get",
    params: {},
  });
}

/**
 * 获取设计列表
 */
export function getDesignedList() {
  return request({
    url: "/design/getFinishedProductList",
    method: "get",
    params: {
      createdAtRank: "desc",
      perPage: 3,
    },
  });
}

//同步设计 /design/syncSystemTemplate

export function syncSystemTemplate({ designId }) {
  return request({
    url: "/design/syncSystemTemplate",
    method: "post",
    data: {
      designId,
    },
  });
}
