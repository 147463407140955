<script setup>
import { ref, onMounted, reactive } from 'vue'
import titleSearch from './components/titleSearch'
import personalizedProductList from './components/personalizedProductList'
import PersonalizedProductSettingsTop from './components/personalizedProductSettingsTop.vue'
import PersonalizedProductSettingsList from './components/personalizedProductSettingsList.vue'
import CopyrightInfo from '@/components/copyrightInfo.vue'
import { ElMessage } from 'element-plus'
import {
  getProductPaginate,
  getSystemTemplates,
} from '@/api/personalizedProduct'

onMounted(() => {
  productPaginate()
  getTemplateList()
})

let page = ref(1)
let productPaginateList = ref([])
let meta = ref({})
let loading = ref(false)
let designType = ref([])
const productPaginate = async () => {
  loading.value = true
  try {
    const { data } = await getProductPaginate({
      page: page.value,
      keyword: keyword.value,
      designType: designType.value,
    })
    productPaginateList.value = data?.data?.list.map((e) => {
      e.show = false
      return e
    })
    meta.value = data?.meta
  } catch (error) {
    ElMessage.error(error || 'Serve Error')
  }
  loading.value = false
}

let handleCommand = (e) => {
  designType.value = []
  !!e && designType.value.push(e)
  productPaginate()
}

let keyword = ref('')

let searchChange = (e) => {
  page.value = 1
  keyword.value = e
  productPaginate()
}

let currentChange = (e) => {
  page.value = e
  productPaginate()
}

let back = () => {
  personalizedProductShow.value = false
}

let personalizedProductShow = ref(false)

//产品个性化设置列表
let keywordChange = (e) => {
  template.keyword = e
  template.page = 1
  getTemplateList()
}

let newProduct = () => {
  personalizedProductShow.value = true
}
let designTemplateType = ref(null)
let changeTemplate = (e) => {
  let arr = []
  !!e && arr.push(e)
  designTemplateType.value = arr.join(',')
  getTemplateList()
}

//获取模板列表

let template = reactive({
  page: 1,
  perPage: 10,
  keyword: '',
  data: [],
  meta: {},
})

let getTemplateList = async () => {
  template.loading = true
  try {
    const { data } = await getSystemTemplates({
      page: template.page,
      perPage: template.perPage,
      keyword: template.keyword,
      designType: designTemplateType.value,
    })
    template.list = data?.data?.list.map(e=>{
      e.asyncDesignLoading = false
      return e
    })
    template.meta = data?.meta
  } catch (error) {
    ElMessage.error(error)
  }
  template.loading = false
}

//系统模板列表分页变动
let templateCurrentChange = (e) => {
  template.page = e
  getTemplateList()
}
</script>

<template>
  <div class="main">
    <div class="w1120">
      <template v-if="!personalizedProductShow">
        <PersonalizedProductSettingsTop
          @keywordChange="keywordChange"
          @newProduct="newProduct"
          @changeTemplate="changeTemplate"
        />
        <PersonalizedProductSettingsList
          :template="template"
          @currentChange="templateCurrentChange"
          v-loading="template.loading"
        />
      </template>
      <template v-else>
        <titleSearch
          @search="searchChange"
          @back="back"
          @handleCommand="handleCommand"
        />
        <personalizedProductList
          v-loading="loading"
          :productPaginateList="productPaginateList"
          :meta="meta"
          @currentChange="currentChange"
        />
      </template>
    </div>
  </div>
  <div class="rightInfo">
    <CopyrightInfo />
  </div>
</template>

<style scoped lang="scss">
.main {
  display: flex;
  justify-content: center;
  padding-bottom: 71px;
  .w1120 {
    width: 1120px;
  }
}
.rightInfo {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  z-index: 9;
}
</style>
